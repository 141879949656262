.indicator {
    min-height: 90px;
    font-size: 1em;

    .image-panel {
        width: 150px;
    }

    .box-yellow{
        background-color: $logoBgColor;
        color: white;
    }

    .box-red{
        background-color: $bg-red;
        color: white;
    }

    .box-blue{
        background-color: $bg-blue;
        color: white;
    }

    .bg-blue-light{
        background-color: $bg-blue-light;
        color: white;
    }

    .box-green{
        background-color: $bg-green;
        color: white;
    }

    .box-purple{
        background-color: $bg-purple;
        color: white;
    }

    .capex{
        font-size: 40px;
        line-height: 90px;
    }

    .info-text{
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .line-head{
        border-bottom: 2px solid #666;
    }

    .line-body {
        border-bottom: 1px solid #CCC;
    }
}
